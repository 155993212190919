
<template>
  <div id="app" class="app">
    <img class="logo" alt="vectornator logo" src="./assets/logo.png" />
    <h1>Share With Vectornator</h1>
    <div class="container">
      <div class="row">
        <div class="col-sm-8 offset-md-2">
          <div class="large">
            By using ‘Share with Vectornator’, you'll be sharing a copy of your
            artwork with the Vectornator team. This will allow us to store and
            review these documents in order to help us develop and improve
            Vectornator's Auto Trace function, along with other tools and
            functionalities.
          </div>
          <p class="smaller">
            All uploaded documents will be stored anonymously and will not be
            shared with anyone outside the company.
          </p>
        </div>
      </div>
    </div>
    <div v-if="!image">
      <form>
        <p class="large">Select the artwork (SVG or vectornator file)</p>
        <input
          title=" " 
          type="file"
          id="file"
          @change="onFileChange"
          accept=".svg,.vectornator"
        />
      </form>

    </div>
    <div class="load-wrapper" v-else>
      <div class="loader" v-if="isLoading">
        <div class="loader-wheel"></div>
        <div class="loader-text"></div>
      </div>
      <button
        v-if="!uploadURL & !isLoading"
        class="button"
        @click="uploadImage"
      >
        Submit File
      </button>
      <img v-if="isSVG" :src="image" />

    </div>

    <p class="large" v-if="uploadURL">Uploaded! Thank you for your submission!</p>
  </div>
</template>
<script>
const axios = require('axios').default
const API_ENDPOINT = "https://68v2rrncxf.execute-api.eu-central-1.amazonaws.com/uploads";
export default {
  name: "app",
  data() {
    return {
      image: "",
      uploadURL: "",
      filename: "",
      isSVG: false,
      isLoading: false,
    };
  },
  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.file = files[0];
      this.createImage(files[0]);
    },
    createImage(file) {
      if (!(file.name.includes(".svg") || file.name.includes(".vectornator"))) {
        return alert(
          "Error! Only svg or vectornator file types are supported!"
        );
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        this.isSVG = e.target.result.includes("data:image/svg+xml");
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function () {
      console.log("Remove clicked");
      this.image = "";
    },
    uploadImage: async function () {
      console.log("Upload clicked");
      this.isLoading = true;
      console.log("filename :", this.file.name);

      const response = await axios.get(API_ENDPOINT, {
         params: {
            filename: this.file.name
          }
      })

      console.log('Response: ', response)
      console.log('Upload URL: ', response.data.uploadURL)
      console.log('Uploading: ', this.image)
      let binary = atob(this.image.split(',')[1])
      let array = []
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      let blobData = new Blob([new Uint8Array(array)], {type: 'application/octet-stream'})
      console.log('Uploading to: ', response.data.uploadURL)
      const result = await fetch(response.data.uploadURL, {
        method: 'PUT',
        body: blobData
      })
      console.log('Result: ', result)
      // Final URL for the user doesn't need the query string params
      this.uploadURL = response.data.uploadURL.split('?')[0]
      this.isLoading = false
    },
  },
};
</script>
    
<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;600&display=swap");
.app {
  color: white;
  min-height: 100vh;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: black;
  padding: 60px 30px 30px;
  background-image: url("./assets/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.load-wrapper {
    width: fit-content;
    margin: 0 auto;
}

p {
  color: white;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.large {
  font-size: 18px;
  margin-bottom: 20px;
}

.smaller {
  font-size: 16px;
}

.button {
  -webkit-border-radius: 30px;
  border-radius: 30px;
  align-items: center;
  width: fit-content;
  margin-bottom: 15px;
  padding: 15px 30px;
  cursor: pointer;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #371444 inset;
  border: solid 2px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(45.68deg, #ff0071 27.76%, #fff800 96.01%);
  background-color: transparent;
  font-family: "Inter";
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  margin-top: 30px;
}

.button:hover {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(48.96deg, #fff800 6.53%, #ff0071 76.08%);
  color: lightgrey;
}

h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  padding-bottom: 30px;
  padding-bottom: 0;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: blue;
}

.logo {
  width: 100px;
  padding-bottom: 30px;
}

.loader {
  width: 60px;
}

.loader-wheel {
  animation: spin 1s infinite linear;
  border: 2px solid rgba(30, 30, 30, 0.5);
  border-left: 4px solid #fff;
  border-radius: 50%;
  height: 50px;
  margin-bottom: 10px;
  width: 50px;
  margin: 0 auto;
}

.loader-text {
  color: #fff;
  font-family: arial, sans-serif;
}

.loader-text:after {
  content: "Loading";
  animation: load 2s linear infinite;
}

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes load {
        0% {
          content: 'Loading';
        }
        33% {
          content: 'Loading.';
        }
        67% {
          content: 'Loading..';
        }
        100% {
          content: 'Loading...';
        }
      }

@media (max-width: 400px) {
  .smaller {
    font-size: 13px;
  }

  .large {
    font-size: 16px;
    margin-bottom: 10px;
  }

  form {
    margin-bottom: 10px !important;
    margin-top: 0px !important;
  }


  .logo {
    width: 80px;
    padding-bottom: 10px;
  }

  .app {
    padding: 10px;
  }

  .button {
    margin-top: 15px;
  }

  .instruct {
    margin: 4px;
  }
}

form {
  margin-bottom: 20px;
  margin-top: 20px;
}

.instruct {
  margin: 10px;
}


#file {
background-color: transparent;
border: transparent;  
color: transparent;
width: 181px;
height: 80px;
padding-top: 30px;
}

input[type=file]::file-selector-button {
background-color: transparent;
color: transparent;
border: transparent;  

}

#file::before {
  content: "Choose File";
  -webkit-border-radius: 30px;
  border-radius: 30px;
  align-items: center;
  width: fit-content;
  margin-bottom: 15px;
  padding: 15px 30px;
  cursor: pointer;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #371444 inset;
  border: solid 2px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(45.68deg, #ff0071 27.76%, #fff800 96.01%);
  background-color: transparent;
  font-family: "Inter";
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  margin-top: 30px;

}

</style>
